import * as React from 'react';


export default function Privacy() {
    return (
        <div className='article-container'>
            <section className='article-title'>
                <h1>Privacy Policy</h1>
            </section>
            <section className='article-content'>
                <p>Version Date: 16th June 2023</p>
                <p>We at Ards Table Tennis Club located at the Queens Hall, West Street, Newtownards, BT23 4DB ("we" or "our" or "us") prioritize the safety and security of all the personal information we collect about you, whether it is obtained through our website or other sources. This Policy outlines our commitments to you in compliance with the General Data Protection Regulation (GDPR) and explains how we collect, store, and utilize your personal information.</p>

                <p>We recommend checking this page periodically to ensure your satisfaction with any changes, as we may update this Policy from time to time. By using our website, you agree to be bound by this Policy.</p>

                <p>If you have any inquiries regarding this Policy or our privacy practices, please contact us via email at <a href="mailto:contact@ardstabletennisclub.com">contact@ardstabletennisclub.com</a> or in writing to the Data Protection Officer, Ards Table Tennis Club, Queens Hall, West Street, Newtownards, BT23 4DB.</p>

                <h2>Privacy Notices</h2>

                <p>In order to fulfill requested services, serve our customers and members, and maintain a productive relationship with you, it is essential for us to collect specific and relevant personal information.</p>

                <p>When we assume the role of a data controller and handle your personal information, we will furnish you with a privacy notice that outlines the precise details of the information we possess about you (such as contact details, address, etc.), how we may utilize your personal information, the purposes behind these uses, and the rights you possess.</p>

                <p>If we directly obtain personal information from you, we will provide this privacy notice at the time of collection. In cases where we receive your personal information indirectly, we will supply this privacy notice when we first establish contact with you, when we initially share the data with another party, or within a month, whichever occurs earlier.</p>

                <p>Normally, we will present this privacy notice to you at the commencement of our relationship. However, if there are any significant updates to the privacy notice, we may inform you about the revised version. We encourage you to regularly revisit this notice for any updates.</p>

                <h2>Changes to this policy</h2>

                <p>Any future changes we make to this Policy will be posted on our website and, if applicable, communicated to you via email. When we make substantial changes to this Policy, we will update the version date at the top of this page. Please check this page regularly to stay informed about any updates or modifications to this Policy. If you object to any alterations, please contact us using the details provided in the "Contact" section below.</p>

                <h2>Contact</h2>

                <p>If you have any queries or complaints regarding the information we hold about you, please email contact@ardstabletennisclub.co.uk or write to the Data Protection Officer at the following address:</p>

                <p>Data Protection Officer<br />
                    Ards Table Tennis Club<br />
                    Queens Hall, West Street, <br />
                    Newtownards, BT23 4DB</p>

                <p>While this privacy policy provides a general overview of your legal rights concerning personal information, it is important to note that this is a complex area of law. For more information about your legal rights, you can visit the Information Commissioner's website at <a href="https://ico.org.uk/for-the-public/">https://ico.org.uk/for-the-public/</a>.</p>

                <h2>How We Utilize Your Personal Information</h2>

                <p>We will employ your personal information as described in the privacy notice provided to you. For instance, we may use your personal information to administer any accounts you hold with us or to send you information that we believe might be beneficial, provided you have consented to receive such communications.</p>

                <h2>How long do we retain your personal information?</h2>

                <p>The duration for which we retain your personal information is detailed in the relevant privacy notice provided to you. However, we will only hold your information for as long as necessary or as requested by you for deletion, in which case we may delete it earlier.</p>

                <p>The retention period for your personal information varies depending on the type of information and the purpose for its collection. In certain cases, personal information may be retained for the long term. For example, personal information required for legal purposes is typically retained for a minimum of six years in accordance with standard commercial practices and regulatory requirements.</p>

                <h2>Linking with third-party sites</h2>

                <p>Our site may occasionally contain links to websites of our commercial partners, other regional bodies, advertisers, and clubs. Please be aware that these websites have their own privacy policies, and they act as data controllers for your personal information. We assume no responsibility or liability for their policies, and we recommend reviewing them before submitting any personal information to these websites.</p>

                <p>In addition, if you accessed this site from a third-party site, we cannot be held responsible for the privacy policies and practices of the owners or operators of that third-party site. We suggest reviewing the policy of that third-party site and contacting its owner or operator if you have any concerns or questions.</p>

                <h2>Log files</h2>

                <p>Similar to most websites, our website records various information about visitors, including internet protocol (IP) addresses, browser types, internet service provider (ISP) details, referring/exit pages, and date/time stamps.</p>

                <p>We may use this information to analyze trends, administer the website, track user movement on the website, and gather general demographic information.</p>

                <h2>Security</h2>

                <p>We employ a range of technical and organizational measures to ensure the security of your personal information and prevent unauthorized access, use, or disclosure. However, it's important to note that no transmission of information over the Internet is entirely secure, nor is the storage of information always 100% secure. Nevertheless, we take all appropriate steps to protect the security of your personal information.</p>

                <h2>The Distinction between Data Controllers/Processors</h2>

                <p>A data controller refers to an individual who controls the processing and usage of personal information. On the other hand, a data processor is an individual who processes and utilizes personal information as per the instructions provided by a third party, which is typically the data controller.</p>

                <p>This differentiation holds significant importance. You possess certain rights concerning your personal information, such as the right to access the personal information held about you, the right to know its usage, and the right to have certain personal information erased or anonymized (commonly known as the right to be forgotten). These rights are typically enforceable against a data controller of your information.</p>

                <p>In most cases, we act as a data controller for your personal information. However, if we are not a data controller in specific instances (for example, when we solely operate as a data processor), you cannot directly exercise these rights against us. In such cases, you can address your requests to the data controller who determines how we process your personal information. We will strive to inform you about the identity of the data controller, enabling you to direct your requests accordingly.</p>

                <p>Furthermore, it is solely the data controller's responsibility to provide you with a privacy notice regarding your personal information. Therefore, when we process your personal information as a data controller, we will furnish you with a privacy notice. If we process your personal information as a data processor for a third party, that third party should provide you with a privacy notice detailing the processing of your personal information, including any processing carried out by us on their behalf.</p>

                <h2>With Whom We Share Your Personal Information</h2>

                <p>The details regarding the disclosure of your personal information are outlined in the relevant privacy notice provided to you. Generally, we disclose your personal information when necessary to manage our organization (e.g., when others process information on our behalf). In such instances, we establish measures to safeguard your personal information. Apart from these situations, we do not disclose your personal information unless required to do so by law.</p>

                <p>If we transfer your personal information outside the European Economic Area (EEA), we will inform you and ensure the implementation of reasonable security measures. Any third-party processors involved will be required to process the information in accordance with data protection laws. If we act as the information controller, we will include this notification in your privacy notice.</p>

                <p>We do not sell, trade, or rent your personal information to any third parties.</p>

                <h2>What are your rights?</h2>

                <p>Full details of your rights are specified in the relevant privacy notice provided to you. By law, you have the right to request a copy of your personal information at any time. You also have the right to request corrections, deletions, or updates to your personal information, to have your personal information sent to you or another organization, and to object to automated decision-making. If you have given consent for the use of your personal information in a specific manner, you have the right to withdraw this consent at any time.</p>

                <p>To exercise your rights or if you have any questions regarding your rights, please contact us using the details provided in the "Contact" section above. You can also unsubscribe from any direct marketing communications by clicking on the unsubscribe link in the marketing messages we send to you.</p>

                <p>Please note that some of your rights may have specific requirements and exemptions, and they may not apply to personal information recorded and stored by us. However, your right to withdraw consent or object to processing for direct marketing are absolute rights.</p>

                <p>If you are dissatisfied with the way we handle your personal information, you can lodge a complaint with the UK Information Commissioner's Office or your local data protection regulator. More information about your legal rights can be found on the Information Commissioner's website at <a href="https://ico.org.uk/for-the-public/">https://ico.org.uk/for-the-public/</a>. However, we are here to assist you and encourage you to contact us first to resolve any complaints.</p>

                <h2>Website disclaimer</h2>

                <p>The Ards Table Tennis Club website and the materials related to information, products, and services (or third-party information, products, and services) are provided "as is" without any representation or endorsement, and without warranty of any kind, whether express or implied. This includes but is not limited to implied warranties of satisfactory quality, fitness for a particular purpose, non-infringement, compatibility, security, and accuracy.</p>

                <p>We do not guarantee that the functions within the materials on this site will be uninterrupted or error-free, that defects will be corrected, or that this site or the server making it available are free of viruses or represent the full functionality, accuracy, and reliability of the materials. In no event shall we be liable for any loss or damage, including indirect or consequential loss or damage, or any loss or damages whatsoever arising from the use or loss of use of data or profits, resulting from or in connection with the use of the Ards Table Tennis Club website.</p>

                <p>If any of these Terms and Conditions are deemed illegal, invalid, or unenforceable by the laws of any state or country in which they are intended to be effective, the specific term or condition shall be severed and deleted, while the remaining terms and conditions shall continue to be binding and enforceable to the fullest extent permitted by law.</p>

                <p>These Terms and Conditions shall be governed by and construed in accordance with the laws of United Kingdom. Any dispute arising under these Terms and Conditions shall be subject to the exclusive jurisdiction of the courts of Northern Ireland.</p>

                <p>If you do not fully accept these Terms and Conditions, you do not have permission to access the contents of this website, and therefore you should immediately cease using this website.</p>

                <p>If you would like further information about any aspect mentioned on this page, please contact:</p>

                <h5>Information Requests Manager</h5>
                <h5>Email: <a href="mailto:contact@ardstabletennisclub.com">contact@ardstabletennisclub.com</a></h5>
            </section>
        </div>
    )
}