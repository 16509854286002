import React from "react";
import useIosInstallPrompt from "../hooks/useIosInstallPrompt";
import useWebInstallPrompt from "../hooks/useWebInstallPrompt";
import Modal from "@mui/material/Modal";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import IosShareIcon from '@mui/icons-material/IosShare';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 200,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  borderRadius: 5,
};

export const InstallPwa = () => {
  const [open] = React.useState(true);
  const [iosInstallPrompt, handleIOSInstallDeclined] = useIosInstallPrompt();
  const [webInstallPrompt, handleWebInstallDeclined, handleWebInstallAccepted] =
    useWebInstallPrompt();

  if (!iosInstallPrompt && !webInstallPrompt) {
    return null;
  }
  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Why not install our app?
        </Typography>
        {iosInstallPrompt && (
          <>
            Tap this icon <IosShareIcon /> in the navigation bar and
            then tap &quot;Add to Home Screen&quot;
            <div className="d-flex justify-content-center">
              <Button onClick={handleIOSInstallDeclined}>Close</Button>
            </div>
          </>
        )}
        {webInstallPrompt && (
          <div className="d-flex justify-content-around">
            <Button color="primary" onClick={handleWebInstallAccepted}>
              Install
            </Button>
            <Button onClick={handleWebInstallDeclined}>Close</Button>
          </div>
        )}
      </Box>
    </Modal>
  );
};
