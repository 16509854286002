import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Routes, Route } from "react-router-dom";
import Footer from './components/Footer';
import Home from './pages/home/Home';
import About from './pages/about/About';
import NewsPage from './pages/news/NewsPage';
import NavBar from './components/NavBar';
import NewsArticle from './pages/news/articles/NewsArticle';
import SpringLeague23 from './pages/competitions/spring-league-23/SpringLeague23';
import Contact from './pages/contact/Contact';
import Membership from './pages/membership/Membership';
import Events from './pages/events/Events';
import Privacy from './pages/privacy/Privacy';
import Cookies from './pages/cookies/Cookies';
import Constitution from './pages/constitution/Constitution'
import Terms from './pages/terms/Terms';
import Report from './pages/report/Report';
import BottomNav from './components/BottomNav';
import Equity from './pages/equity/Equity';
import MatchReport from './pages/competitions/MatchReport';
import EventResults from './pages/competitions/des-flanagan-series-sep-23/EventResults'
import Competitions from './pages/competitions/Competitions'
import Rankings from './pages/rankings/Rankings'
import { InstallPwa } from './components/InstallPwa';
import SpringLeague24 from './pages/competitions/spring-league-24/SpringLeague24';
import PlayerProfile from './pages/players/PlayerProfile';
import TeamProfile from './pages/teams/TeamProfile';
import CacheBuster from 'react-cache-buster';


function App() {
  // Set app version from package.json so that main.js gets new hash for each version
  // to ensure browswer gets latest version after each release
  const APP_VERSION = process.env.REACT_APP_VERSION

  // This forces the app to refresh when someone resumes it
  // This is a temporary way to keep the app updated but should be removed
  // once push notification are live as they can be used to trigger the reload 
  window.addEventListener('visibilitychange', function () {
    if (document.visibilityState === 'visible') {
      if (window.matchMedia('(display-mode: standalone)').matches) {
        window.location.reload();
      }
    }
  });

  return (
    <CacheBuster
      currentVersion={APP_VERSION}
      isEnabled={true} //If false, the library is disabled.
      isVerboseMode={false} //If true, the library writes verbose logs to console.
      metaFileDirectory={'.'} //If public assets are hosted somewhere other than root on your server.
    >
      <div className="App">
        <InstallPwa />
        <Routes>
          <Route path="/" element={<NavBar />}>
            <Route index element={<Home />} />
            <Route path="/about-us" element={<About />} />
            <Route path="/membership" element={<Membership />} />
            <Route path="/competitions/ards-and-district-spring-league-23" element={<SpringLeague23 />} />
            <Route path="/competitions/ards-and-district-spring-league-24" element={<SpringLeague24 />} />
            <Route path="/events" element={<Events />} />
            <Route path="/rankings" element={<Rankings />} />
            <Route path="news" element={<NewsPage />} />
            <Route path="/news/:id" element={<NewsArticle />} />
            <Route path="/competitions" element={<Competitions />} />
            <Route path="/competitions/ards-and-district-spring-league-23/:id" element={<MatchReport />} />
            <Route path="/competitions/ards-and-district-spring-league-24/:id" element={<MatchReport />} />
            <Route path="/competitions/des-flanagan-series-sep-23/:eventId" element={<EventResults />} />
            <Route path="/players/" element={<PlayerProfile />} />
            <Route path="/players/:id" element={<PlayerProfile />} />
            <Route path="/teams/" element={<TeamProfile />} />
            <Route path="/teams/:id" element={<TeamProfile />} />
            <Route path="/contact-us" element={<Contact />} />
            <Route path="/privacy-policy" element={<Privacy />} />
            <Route path="/equity-policy" element={<Equity />} />
            <Route path="/cookie-policy" element={<Cookies />} />
            <Route path="/club-constitution" element={<Constitution />} />
            <Route path="/terms-of-use" element={<Terms />} />
            <Route path="/report-an-issue" element={<Report />} />
            <Route path="*" element={<Home />} />
          </Route>
        </Routes>
        <Footer />
        <BottomNav />
      </div>
    </CacheBuster>
  );
}

export default App;
