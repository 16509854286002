import * as React from 'react';
import News from '../../../components/News.js'
import articles from '../../news/news.json';
import LeagueTable from '../../../components/LeagueTable.js';
import FixtureCard from '../../../components/FixtureCard.js';
import SelectBox from '../../../components/SelectBox.js';
import leagueData from '../../../local-data-stores/spring-league-23-tables.json';
import fixtureData from '../../../local-data-stores/spring-league-23-fixtures.json';
import { Link } from "react-router-dom";


export default function SpringLeague23() {
    const [division, setDivision] = React.useState('all');

    function handleSelectedGroups(data) {
        setDivision(data);
    }

    const newsArticles = articles.filter(article => article.event === 'spring-league-23')

    return (
        <div className='page-container' >
            <div className='brand-title' style={{ paddingLeft: '10px' }}>
                <h1>Ards & District Spring League 2023</h1>
                <h1>League News</h1>
            </div>
            <section className='scrolling-news scroller'>
                <News newsArticles={newsArticles} />
            </section>
            <section className='league-tables'>
                <div className='Left-body'>
                    <div className='brand-title' style={{ paddingLeft: '10px' }}>
                        <div className='flex-space'>
                            <h1>League Tables</h1>
                            <SelectBox onSelectedGroups={handleSelectedGroups} />
                        </div>
                    </div>
                    <div>
                        {leagueData.tables.map(table => {
                            if (!division || division === table.tableName || division === "all") {
                                return <div key={`${leagueData.name}-${table.tableName}`} className='brand-title'>
                                    <div className='league-division-container'>
                                        <h2>{table.tableName}</h2>
                                        <LeagueTable leagueName={leagueData.name} table={table} />
                                    </div>
                                </div>
                            } else {
                                return null
                            }
                        })}
                    </div>
                </div>
                <div className='Right-body'>
                    <div className='fixture-cards scroller'>
                        <div>
                            {fixtureData.map(fixture => {
                                if (fixture.division === division || division === "all") {
                                    return <Link
                                        key={fixture.fixtureId}
                                        className='card-links'
                                        to={`/competitions/ards-and-district-spring-league-23/${fixture.fixtureId}`}>

                                        <FixtureCard
                                            title={fixture.date}
                                            home={fixture.home}
                                            away={fixture.away}
                                            division={fixture.division}
                                        />
                                    </Link>
                                } else {
                                    return null;
                                }
                            })}
                        </div>
                    </div>
                </div>
            </section >
        </div >
    )
}