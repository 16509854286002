import * as React from 'react';
import Carousel from 'react-bootstrap/Carousel';
import { Link } from 'react-router-dom';

function CarouselFadeExample({sliderItems, interval}) {
  return (
    <Carousel fade interval={interval}>
      {sliderItems.map(item =>
        <Carousel.Item key={item.id}>
          <Link to={item.link}>
            <img
              className="d-block w-100"
              src={item.src}
              alt={item.alt}
            />
          </Link>
          <Carousel.Caption className='carousel-modified'>
            <h3>{item?.heading}</h3>
            <p>{item?.subheading}</p>
          </Carousel.Caption>
        </Carousel.Item>
        
      )}
    </Carousel>
  );
}

export default CarouselFadeExample;