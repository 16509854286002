import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { CardActionArea } from '@mui/material';

export default function FixtureCard({ title, home, away, division }) {
  return (
    <Card sx={{
      maxWidth: 345, margin: 1, background: "linear-gradient(135deg, #0a2540 65%, #1976d2)",
      color: "white"
    }}>
      <CardActionArea>
        <div className='fixtures'>
          <CardMedia
            component="img"
            height="80"
            image={home.path ? home.path : "/images/team-logos/default-home.png"}
            alt="team logo"
            sx={{ margin: "15px", border: "2px solid #282c34", borderRadius: "5px", maxWidth: "110px" }}
          />
          <div className='fixtures-vs-center'>
            <p>{title}</p>
            <p>VS</p>
            <p>{division}</p>
          </div>
          <CardMedia
            component="img"
            height="80"
            image={away.path ? away.path : "/images/team-logos/default-away.png"}
            alt="team logo"
            sx={{ margin: "15px", border: "2px solid #282c34", borderRadius: "5px", maxWidth: "110px" }}
          />
        </div>
        <CardContent sx={{ paddingTop: 0 }}>
          <div className='fixtures'>
            <div className='score-group'>
              <p className='semi-bold'>Home</p>
              <p>{home.name}</p>
              <p className="score-outline">{home.score}</p>
            </div>
            <div className='fixtures-vs-center'>
              <p className='final-score'>Final Score</p>
            </div>
            <div className='score-group'>
              <p className='semi-bold'>Away</p>
              <p>{away.name}</p>
              <p className='score-outline'>{away.score}</p>
            </div>
          </div>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}