import React from 'react';
import { useParams, Link } from 'react-router-dom';
import '../../App.css';
import teams from '../../local-data-stores/teams.json';
import players from '../../local-data-stores/players.json';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Avatar from '@mui/material/Avatar';
import FixtureCard from '../../components/FixtureCard';
import fixtureData from '../../local-data-stores/spring-league-24-fixtures.json'

const imgStyles = {
    fontSize: 300,
    border: '1px solid gray',
    margin: '0px 0px 25px 0'
};

function getRegisteredPlayers(team) {
    const registeredPlayers = team.players.map(playerId => players.find(player => player.playerId === playerId));
    return registeredPlayers;
}

function calculateStats(fixtures, id) {
    let wins = 0;
    let losses = 0;
    let draws = 0;
    let winRatio = 0;
    if (fixtures && fixtures.length > 0) {
        fixtures.forEach(fixture => {
            const homeTeam = fixture.home.teamId;
            const awayTeam = fixture.away.teamId;

            if (typeof fixture.away.score === 'number') {
                if (homeTeam === id) {
                    if (fixture.home.score > fixture.away.score) {
                        wins++;
                    } else if (fixture.home.score === fixture.away.score) {
                        draws++;
                    } else {
                        losses++;
                    }
                } else if (awayTeam === id) {
                    if (fixture.away.score > fixture.home.score) {
                        wins++;
                    } else if (fixture.away.score === fixture.home.score) {
                        draws++;
                    } else {
                        losses++;
                    }
                }
            }
        });
    }

    const totalMatches = wins + losses + draws;
    winRatio = totalMatches > 0 ? (wins / totalMatches) * 100 : 0;
    return [totalMatches, wins, draws, losses, winRatio.toFixed()];
}

export default function TeamProfile() {
    const { id } = useParams();

    // Get Team Details
    const team = teams.find(team => team.teamId === id);

    // Get the registered players for the team
    const registeredPlayers = team ? getRegisteredPlayers(team) : [];

    const teamFixturesOnly = fixtureData.filter(fixture => {
        return fixture.home.teamId === id || fixture.away.teamId === id;
    })

    const [totalMatches, overallWins, overallDraws, overallLosses, overallWinRatio] = calculateStats(teamFixturesOnly, id);


    return (
        <div className='article-container'>
            <section className='article-title'>
                <h1 className='xl-title'>{team ? team.name : 'Not Found'}</h1>
            </section>
            <section className='player-bio-section'>
                <div className='player-bio brand-title'>
                    <h1>Bio</h1>
                    <p>{team ? "If you would like your team's profile here please contact chris.brennan@ardstabletennisclub.com" : "This team has not registered in our system. Please contact chris.brennan@ardstabletennisclub.com to have them added"}</p>
                </div>
                {team &&
                    <div className='player-pic'>
                        <img style={imgStyles} src={team.logo} height={300} width={300} alt='team-logo' />
                        <div className='player-overall-stats'>
                        <div className='player-circle-stats'>
                                <Avatar sx={{ bgcolor: "#0c2e4e", color: "#ffffff", width: 75, height: 75 }}>{totalMatches}</Avatar>
                                <p>Played</p>
                            </div>
                            <div className='player-circle-stats'>
                                <Avatar sx={{ bgcolor: "#00d4ff", color: "#0a2640", width: 75, height: 75 }}>{overallWins}</Avatar>
                                <p>Wins</p>
                            </div>
                            <div className='player-circle-stats'>
                                <Avatar sx={{ bgcolor: "#0c2e4e", color: "#ffffff", width: 75, height: 75 }}>{overallDraws}</Avatar>
                                <p>Draws</p>
                            </div>
                            <div className='player-circle-stats'>
                                <Avatar sx={{ bgcolor: "#0c2e4e", color: "#ffffff", width: 75, height: 75 }}>{overallLosses}</Avatar>
                                <p>Losses</p>
                            </div>
                            <div className='player-circle-stats'>
                                <Avatar sx={{ bgcolor: "#00d4ff", color: "#0a2640", width: 75, height: 75 }}>{overallWinRatio}%</Avatar>
                                <p>Average</p>
                            </div>
                        </div>
                    </div>
                }
            </section>
            <section className='brand-title'>
                <h1>Players</h1>
                <TableContainer component={Paper}>
                    <Table aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{ fontWeight: 600, backgroundColor: '#f2f2f4' }}>Player</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {registeredPlayers.map(player => (
                                <TableRow key={`team-profile-${player.playerId}`}>
                                    <TableCell>
                                        <Link to={`/players/${player.playerId}`}>
                                            {player.firstname} {player.lastname}
                                        </Link>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </section>
            <section className='p-top-20'>
                <div className='brand-title'>
                    <h1>Fixtures: Ards & District 2024</h1>
                </div>
                <div className='team-fixtures'>
                    {teamFixturesOnly.map(fixture => {
                        return <Link
                            key={fixture.fixtureId}
                            className='card-links'
                            to={`/competitions/ards-and-district-spring-league-24/${fixture.fixtureId}`}
                        >
                            <FixtureCard
                                title={fixture.date}
                                home={fixture.home}
                                away={fixture.away}
                                division={fixture.division}
                            />
                        </Link>
                    })}

                </div>
            </section>
        </div>
    );
}
