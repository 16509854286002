import * as React from 'react';
import newsArticles from './news.json'
import News from '../../components/News';


export default function NewsPage() {
    return (
        <div className='news-body'>
            <div className='brand-title center'>
                <h1>News</h1>
            </div>
            <div className='news-cards'>
                <News newsArticles={newsArticles}/>
            </div>
        </div>
    )
}