import * as React from 'react';
import { useParams } from 'react-router-dom';
import '../../App.css'
import springLeague23 from '../../local-data-stores/spring-league-23-fixtures.json';
import springLeague24 from '../../local-data-stores/spring-league-24-fixtures.json';
import players from '../../local-data-stores/players.json';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import PersonIcon from '@mui/icons-material/Person';
import { Avatar } from '@mui/material';


// Function to filter player matches
function getMatches(data, id) {
    const matches = [];
    data.forEach(competition => {
        competition.matches.forEach(match => {
            const homePlayer = match.home.playerId;
            const awayPlayer = match.away.playerId;
            if (homePlayer === id || awayPlayer === id) {
                matches.push(match);
            }
        });
    });
    return matches;
}

function calculateStats(matches, id) {
    let wins = 0;
    let losses = 0;
    let gameWins = 0;
    let gameLosses = 0;

    if (matches && matches.length > 0) {
        matches.forEach(match => {
            const homePlayer = match.home.playerId;
            const awayPlayer = match.away.playerId;

            if (homePlayer === id || awayPlayer === id) {
                // Determine if the player is home or away
                const isHomePlayer = homePlayer === id;

                // Calculate match results
                const playerScore = isHomePlayer ? match.home.matchScore : match.away.matchScore;
                const opponentScore = isHomePlayer ? match.away.matchScore : match.home.matchScore;

                if (playerScore > opponentScore) {
                    wins++;
                } else {
                    losses++;
                }

                // Track game results
                gameWins += playerScore;
                gameLosses += opponentScore;
            }
        });
    }

    const totalMatches = wins + losses;
    const totalGames = gameWins + gameLosses;
    const gameRatio = totalGames > 0 ? (gameWins / totalGames) * 100 : 0;
    const winRatio = totalMatches > 0 ? (wins / totalMatches) * 100 : 0;

    return [totalMatches, wins, losses, gameRatio.toFixed(), winRatio.toFixed()];
}


export default function PlayerProfile() {
    const { id } = useParams();

    // Get Player Profile Details
    const [player] = players.filter(player => {
        return player.playerId === id
    })

    // Get Spring League 23 Stats
    const springLeague23Matches = getMatches(springLeague23, id);
    const [totalMatches23, wins23, losses23, gameRatio23, winRatio23] = calculateStats(springLeague23Matches, id);

    // Get Spring League 24 Stats
    const springLeague24Matches = getMatches(springLeague24, id);
    const [totalMatches24, wins24, losses24, gameRatio24, winRatio24] = calculateStats(springLeague24Matches, id);

    // Get overall results
    const overallMatches = springLeague23Matches.concat(springLeague24Matches);
    const [totalMatches, overallWins, overallLosses, overallGameRatio, overallWinRatio] = calculateStats(overallMatches, id);

    return (
        <div className='article-container'>
            <section className='article-title'>
                {player ?
                    <h1 className='xl-title'>{player.firstname} {player.lastname}</h1>
                    :
                    <h1 className='xl-title'>Not Found</h1>
                }
            </section>
            <section className='player-bio-section'>
                <div className='player-bio brand-title'>
                    <h1>Bio</h1>
                    {player ?
                        <p>If you would like your bio and a profile picture added here please contact chris.brennan@ardstabletennisclub.com</p>
                        :
                        <p>This player has not been registered in our system. Please contact chris.brennan@ardstabletennisclub.com to have them added</p>
                    }
                </div>
                <div className='player-pic'>
                    <PersonIcon sx={{ fontSize: 300, border: '1px solid gray', margin: '0px 0px 25px 0' }} />
                    <div className='player-overall-stats'>
                        <div className='player-circle-stats'>
                            <Avatar sx={{ bgcolor: "#0c2e4e", color: "#ffffff", width: 75, height: 75 }}>{totalMatches}</Avatar>
                            <p>Played</p>
                        </div>
                        <div className='player-circle-stats'>
                            <Avatar sx={{ bgcolor: "#00d4ff", color: "#0a2640", width: 75, height: 75 }}>{overallWins}</Avatar>
                            <p>Wins</p>
                        </div>
                        <div className='player-circle-stats'>
                            <Avatar sx={{ bgcolor: "#0c2e4e", color: "#ffffff", width: 75, height: 75 }}>{overallLosses}</Avatar>
                            <p>Losses</p>
                        </div>
                        <div className='player-circle-stats'>
                            <Avatar sx={{ bgcolor: "#00d4ff", color: "#0a2640", width: 75, height: 75 }}>{overallGameRatio}%</Avatar>
                            <p>Game</p>
                        </div>
                        <div className='player-circle-stats'>
                            <Avatar sx={{ bgcolor: "#0c2e4e", color: "#ffffff", width: 75, height: 75 }}>{overallWinRatio}%</Avatar>
                            <p>Match</p>
                        </div>
                    </div>
                </div>
            </section>
            <section className='brand-title'>
                <h1>Player Statistics</h1>
                <TableContainer component={Paper} >
                    <Table className='mobile-table' aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{ fontWeight: 600, backgroundColor: '#f2f2f4' }}>Event</TableCell>
                                <TableCell sx={{ fontWeight: 600, backgroundColor: '#f2f2f4' }}>P</TableCell>
                                <TableCell sx={{ fontWeight: 600, backgroundColor: '#f2f2f4' }}>W</TableCell>
                                <TableCell sx={{ fontWeight: 600, backgroundColor: '#f2f2f4' }}>L</TableCell>
                                <TableCell sx={{ fontWeight: 600, backgroundColor: '#f2f2f4' }}>Game (%)</TableCell>
                                <TableCell sx={{ fontWeight: 600, backgroundColor: '#f2f2f4' }}>Match (%)</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell>
                                    Spring League 24: The Jack Cash Cup
                                </TableCell>
                                <TableCell>
                                    {totalMatches24}
                                </TableCell>
                                <TableCell>
                                    {wins24}
                                </TableCell>
                                <TableCell>
                                    {losses24}
                                </TableCell>
                                <TableCell>
                                    {gameRatio24}%
                                </TableCell>
                                <TableCell>
                                    {winRatio24}%
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    Spring League 23: The Jack Cash Cup
                                </TableCell>
                                <TableCell>
                                    {totalMatches23}
                                </TableCell>
                                <TableCell>
                                    {wins23}
                                </TableCell>
                                <TableCell>
                                    {losses23}
                                </TableCell>
                                <TableCell>
                                    {gameRatio23}%
                                </TableCell>
                                <TableCell>
                                    {winRatio23}%
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </section>
        </div>
    )
}