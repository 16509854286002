import * as React from 'react';


export default function Terms() {
    return (
        <div className='article-container'>
            <section className='article-title'>
                <h1>Terms of Use</h1>
                <h2>Terms of Use for Ards Table Tennis Club</h2>
                <h5>Ards Table Tennis Club</h5>
                <h5>Address: Queens Hall, West Street, Newtownards, BT23 4DB</h5>
                <h5>Email: <a href="mailto:contact@ardstabletennisclub.com">contact@ardstabletennisclub.com</a></h5>
            </section>
            <section className='article-content'>
                <p>By accessing and using the Ards Table Tennis Club website, each person/member agrees to comply with and be bound by the following terms and conditions:</p>

                <h2>1. Membership Obligations</h2>
                <p>All individuals are subject to the operational rules and regulations of Ards Table Tennis Club. Members agree to observe all codes of conduct, regulations, rules, and policies established by Ards Table Tennis Club, including but not limited to:</p>

                <ol>
                    <li>Safeguarding Policies</li>
                    <li>Anti-Corruption Measures</li>
                    <li>Social Media Guidelines</li>
                </ol>

                <h2>2. Copyright</h2>
                <p>All rights reserved, including copyright and database rights. All materials on the Ards Table Tennis Club website are protected by copyright owned by Ards Table Tennis Club.</p>

                <p>Members may reproduce the website materials for research, private study, and personal non-commercial use, provided that the material is accurately reproduced and not used in a misleading context.</p>

                <p>Members may download and use the website content on a single CPU at a time and print a single hard copy for personal use.</p>

                <p>No part of the website may be reproduced, stored in a retrieval system, or transmitted in any form or by any means, electronic, mechanical, photocopying, recording, or otherwise without prior permission from Ards Table Tennis Club.</p>

                <p>Requests to use copyrighted material should be sent to <a href="mailto:contact@ardstabletennisclub.com">contact@ardstabletennisclub.com</a>.</p>

                <h2>3. Disclaimer of Liability</h2>
                <p>The information provided on the Ards Table Tennis Club website is for convenience and general purposes only. While every effort has been made to ensure the accuracy of the information, Ards Table Tennis Club, its representatives, or agents shall not be held responsible or liable for any loss, damage, or costs arising directly or indirectly from reliance on the material on the website or any other guidelines or policies issued by Ards Table Tennis Club. Visitors who rely on this information do so at their own risk.</p>

                <p>Guidance and club policies are subject to change. Ards Table Tennis Club reserves the right to amend, supplement, or discontinue any guidelines outlined on the website at its discretion.</p>

                <p>Ards Table Tennis Club excludes all liability for loss, disappointment, negligence, or other damage arising from the bankruptcy, liquidation, or cessation of trade of any mentioned company, individual, or firm.</p>

                <p>Similarly, no adverse inference should be drawn from the omission of any organization, person, or other information from the website, as the content is determined solely by Ards Table Tennis Club.</p>

                <h2>5. Hyperlinking</h2>
                <p>Permission is not required to link directly to pages hosted on the Ards Table Tennis Club website. However, the club's logo may not be used as a link without prior permission. Ards Table Tennis Club's pages must load into the user's entire window and may not be loaded into frames on external sites.</p>

                <h2>4. Intellectual Property</h2>
                <p>The names, images, pictures, and logos identifying Ards Table Tennis Club are proprietary marks. Copying these logos or any third-party logos accessed through the website is not permitted without prior approval from the relevant copyright owner.</p>

                <p>Requests to use the Ards Table Tennis Club logo should be directed to <a href="mailto:contact@ardstabletennisclub.com">contact@ardstabletennisclub.com</a>, providing details of the intended use, contact information, and relevant copyright details.</p>

                <h2>6. Virus Protection</h2>
                <p>While every effort is made to check and test the material on the website, it is advisable to run an anti-virus program on all downloaded materials. Ards Table Tennis Club cannot accept responsibility for any loss, disruption, or damage to data or computer systems that may occur while using material derived from the website.</p>

                <h2>7. Website Disclaimer</h2>
                <p>The Ards Table Tennis Club website, along with its content and any third-party information, products, and services, are presented without any guarantee, endorsement, or warranty of any kind, whether expressed or implied. This encompasses warranties such as satisfactory quality, fitness for a specific purpose, non-infringement, compatibility, security, and accuracy.</p>

                <p>Ards Table Tennis Club does not guarantee uninterrupted or error-free functionality of the website, the correction of defects, or the absence of viruses. In no event shall Ards Table Tennis Club be liable for any loss or damage, including indirect or consequential loss or damage, arising from the use of the website or the loss of data or profits.</p>

                <p>If any of these terms and conditions are deemed illegal, invalid, or unenforceable in any jurisdiction, that term or condition shall be severed and deleted, while the remaining terms and conditions shall continue to be binding and enforceable.</p>

                <p>These terms and conditions are governed by the laws of the United Kingdom, and any dispute arising from them shall be subject to the exclusive jurisdiction of the courts of Northern Ireland.</p>

                <p>If you do not accept these terms and conditions in full, you are not permitted to access the Ards Table Tennis Club website and should discontinue its use immediately.</p>

                <p>For further information, please contact:</p>

                <h5>Information Requests Manager</h5>
                <h5>Email: <a href="mailto:contact@ardstabletennisclub.com">contact@ardstabletennisclub.com</a></h5>
            </section>
        </div >
    )
}