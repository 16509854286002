import * as React from 'react';


export default function Constitution() {
    return (
        <div className='page-container'>
            <section className='brand-title-blue'>
                <h1>Constitution</h1>
            </section>
        </div>
    )
}