import * as React from 'react';


export default function Cookies() {
    return (
        <div className='article-container'>
            <section className='article-title'>
                <h1>Cookie Policy</h1>
                <h2>Cookies</h2>
            </section>
            <section className='article-content'>
                <p>Effective Date: 17th June 2023</p>

                <p>This Cookie Policy explains how cookies and similar technologies are used on Ards Table Tennis Club (referred to as "the website," "we," "us," or "our"). By using our website, you consent to the use of cookies and similar technologies as described in this policy.</p>

                <h2>What Are Cookies?</h2>

                <p>
                    Cookies are small text files that are placed on your computer or mobile device when you visit a website. They are widely used to make websites work more efficiently and to provide information to website owners. Cookies enable the website to remember your actions and preferences (such as login, language, font size, and other display preferences) over a period of time, so you don't have to keep re-entering them whenever you come back to the site or browse from one page to another.
                </p>

                <h2>
                    How We Use Cookies
                </h2>

                <p>
                    We use cookies for various purposes, including:
                </p>

                <ol style={{ "listStyleType": "decimal" }}>
                    <li>
                        Essential Cookies: These cookies are necessary for the website to function properly. They enable you to navigate the website and use its features. Without these cookies, certain services may not be available to you.
                    </li>
                    <li>
                        Analytics Cookies: We use Google Analytics, a web analytics service provided by Google LLC, to collect information about how visitors use our website. This information helps us analyze website traffic, improve the user experience, and understand how visitors interact with our content. Google Analytics cookies collect anonymous and aggregated data, including the number of visitors to the site, the pages they visit, and the duration of their visit. For more information on how Google Analytics handles data, please refer to Google's Privacy Policy.
                    </li>
                    <li>
                        Preference Cookies: These cookies allow the website to remember choices you make (such as your preferred language or region) and provide enhanced, more personalized features. They may also be used to provide services you have requested, such as remembering your login details or preferences.
                    </li>
                    <li>
                        Advertising Cookies: We may use advertising cookies to deliver targeted advertisements that are relevant to your interests. These cookies may track your browsing habits and collect information about your online activity to display personalized advertisements on our website or other websites you visit.
                    </li>
                </ol>


                <h2>
                    Cookie Consent and Control
                </h2>

                <p>When you visit our website, we will request your consent to the use of cookies. By clicking "Accept" or continuing to use our website, you consent to the use of cookies in accordance with this Cookie Policy.</p>

                <p>You can control and manage cookies in your browser settings. Most web browsers allow you to accept or reject cookies, delete specific cookies, or delete all cookies when you close your browser. However, please note that if you disable or delete cookies, you may not be able to access certain features or enjoy the full functionality of our website.</p>

                <p>You can also adjust your advertising preferences or opt-out of personalized advertising by visiting the websites of relevant third-party advertisers or through the opt-out mechanisms provided by industry bodies in your country.</p>

                <h2>
                    Updates to this Cookie Policy
                </h2>

                <p>We may update this Cookie Policy from time to time to reflect changes in technology, legal requirements, or our practices. We encourage you to review this policy periodically to stay informed about how we use cookies.</p>

                <h2>
                    Contact Us
                </h2>

                <p>If you have any questions or concerns about our use of cookies or this Cookie Policy, please contact us at <a href="mailto:contact@ardstabletennisclub.com">contact@ardstabletennisclub.com</a></p>
            </section >
        </div >
    )
}