import * as React from 'react';
import News from '../../../components/News.js'
import articles from '../../news/news.json';
import LeagueTable from '../../../components/LeagueTable.js';
import FixtureCard from '../../../components/FixtureCard.js';
import SelectBox from '../../../components/SelectBox.js';
import fixtureData from '../../../local-data-stores/spring-league-24-fixtures.json';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import moment from "moment";
import { Link } from "react-router-dom";

function formatDate(isoDate) {
    return moment(isoDate).format('ddd Do MMMM YYYY')
}

function getFixturesByDate(fixturesData, division) {
    const fixturesByDate = {};

    fixturesData.forEach(fixture => {
        const { date } = fixture;

        if (!fixturesByDate[date] && (fixture.division === division)) {
            fixturesByDate[date] = [];
        }

        if (fixture.division === division) {
            fixturesByDate[date].push(fixture);
        }
    });

    return fixturesByDate;
}

function generateLeagueTable(matchReports, tableName) {
    // Initialize an object to store team data
    const teamData = {};
    const competition = matchReports[0].competition

    // Iterate over each match report
    matchReports.forEach(fixture => {
        const { home, away, matches } = fixture;


        // Initialize team data if not already present
        if (!teamData[home.name]) {
            teamData[home.name] = {
                team: home.name,
                p: 0, // Matches played
                w: 0, // Matches won
                l: 0, // Matches lost
                d: 0, // Matches drawn
                f: 0, // Goals scored
                pts: 0 // Total points
            };
        }
        if (!teamData[away.name]) {
            teamData[away.name] = {
                team: away.name,
                p: 0,
                w: 0,
                l: 0,
                d: 0,
                f: 0,
                pts: 0
            };
        }

        // check that matches has been played before updating table
        if (typeof home.score === 'number') {

            // Update match data
            teamData[home.name].p++;
            teamData[away.name].p++;

            // Update points based on overall fixture result
            if (home.score > away.score) {
                teamData[home.name].pts += 3; // 3 points for team win
                teamData[home.name].w++
                teamData[away.name].l++;
            } else if (away.score > home.score) {
                teamData[away.name].pts += 3; // 3 points for team win
                teamData[away.name].w++
                teamData[home.name].l++;
            } else {
                teamData[home.name].pts += 2; // 2 points for team draw
                teamData[away.name].pts += 2;
                teamData[home.name].d++; // 2 points for team draw
                teamData[away.name].d++;
            }

            // Update points based on individual match results
            matches.forEach(match => {
                if (match.home.matchScore > match.away.matchScore) {
                    teamData[home.name].pts += 1; // 1 points for a match win
                    teamData[home.name].f++;
                } else if (match.home.matchScore < match.away.matchScore) {
                    teamData[away.name].pts += 1; // 1 points for a match win
                    teamData[away.name].f++;
                }
            });
        }
    });

    // Extract the values (team data objects) and return them as an array
    return {
        competition,
        tableName,
        data: Object.values(teamData)
    }
}



export default function SpringLeague24() {
    const [division, setDivision] = React.useState('all');

    function handleSelectedGroups(data) {
        setDivision(data);
    }

    const newsArticles = articles.filter(article => article.event === 'spring-league-24')

    // Get fixtures by date for upcoming fixutures tables
    const fixturesDateDiv1 = getFixturesByDate(fixtureData, "Division 1");
    const fixturesDateDiv2 = getFixturesByDate(fixtureData, "Division 2");

    // Get division 1 fixtures and generate league table data
    const fixturesDiv1 = fixtureData.filter((fixture) => {
        return fixture.division === "Division 1"
    })
    const tableDiv1 = generateLeagueTable(fixturesDiv1, "Division 1")

    // Get division 1 fixtures and generate league table data
    const fixturesDiv2 = fixtureData.filter((fixture) => {
        return fixture.division === "Division 2"
    })
    const tableDiv2 = generateLeagueTable(fixturesDiv2, "Division 2")

    const leagueTables = [tableDiv1, tableDiv2]

    // Get cards of matches that have been played and sort by most recent date
    const fixtureCards = fixtureData.filter(fixture => typeof fixture.home.score === "number").sort((a, b) => new Date(b.date) - new Date(a.date))

    return (
        <div className='page-container' >
            <div className='brand-title' style={{ paddingLeft: '10px' }}>
                <h1>Ards & District Spring League 2024</h1>
                <h1>League News</h1>
            </div>
            <section className='scrolling-news scroller'>
                <News newsArticles={newsArticles} />
            </section>

            <section className='fixture-dates'>
                <div className='brand-title' style={{ paddingLeft: '10px' }}>
                    <div className='flex-space'>
                        <h1>Fixtures</h1>
                        <SelectBox onSelectedGroups={handleSelectedGroups} />
                    </div>

                    {(division === "Division 1" || division === "all") ?
                        <Accordion defaultExpanded>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1-content"
                                id="panel1-header"
                            >
                                <h1>Division 1</h1>
                            </AccordionSummary>
                            <AccordionDetails>
                                <TableContainer component={Paper}>
                                    <Table className='mobile-table'>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Date</TableCell>
                                                <TableCell align='center'>Match 1</TableCell>
                                                <TableCell align='center'>Match 2</TableCell>
                                                <TableCell align='center'>Match 3</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {Object.entries(fixturesDateDiv1).map(([date, fixtures]) => (
                                                <TableRow key={date}>
                                                    <TableCell>{formatDate(date)}</TableCell>
                                                    {fixtures.map(fixture => (
                                                        <TableCell key={fixture.fixtureId} align='center'>
                                                            <Link className='accordian-table-links' to={`/teams/${fixture.home.teamId}`}>
                                                                {fixture.home.name}
                                                            </Link>
                                                            <br /> VS <br />
                                                            <Link className='accordian-table-links' to={`/teams/${fixture.away.teamId}`}>
                                                                {fixture.away.name}
                                                            </Link>
                                                        </TableCell>
                                                    ))}
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </AccordionDetails>
                        </Accordion>
                        : null}


                    {(division === "Division 2" || division === "all") ?
                        <Accordion defaultExpanded>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1-content"
                                id="panel1-header"
                            >
                                <h1>Division 2</h1>
                            </AccordionSummary>
                            <AccordionDetails>
                                <TableContainer component={Paper}>
                                    <Table className='mobile-table'>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Date</TableCell>
                                                <TableCell align='center'>Match 1</TableCell>
                                                <TableCell align='center'>Match 2</TableCell>
                                                <TableCell align='center'>Match 3</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody >
                                            {Object.entries(fixturesDateDiv2).map(([date, fixtures]) => (
                                                <TableRow key={date}>
                                                    <TableCell>{formatDate(date)}</TableCell>
                                                    {fixtures.map(fixture => (
                                                        <TableCell key={fixture.fixtureId} align='center'>
                                                            <Link className='accordian-table-links' to={`/teams/${fixture.home.teamId}`}>
                                                                {fixture.home.name}
                                                            </Link>
                                                            <br />VS<br />
                                                            <Link className='accordian-table-links' to={`/teams/${fixture.away.teamId}`}>
                                                                {fixture.away.name}
                                                            </Link>
                                                        </TableCell>
                                                    ))}
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </AccordionDetails>
                        </Accordion>
                        : null}
                </div>
            </section >
            <div className='brand-title' style={{ paddingLeft: '10px' }}>
                <div className='flex-space'>
                    <h1>League Tables</h1>
                </div>
            </div>
            <div>

                {leagueTables.map(table => {
                    if (!division || division === table.tableName || division === "all") {
                        return <div key={`${table.competition}-${table.tableName}`} className='brand-title'>
                            <div className='league-division-container'>
                                <h2>{table.tableName}</h2>
                                <LeagueTable leagueName={table.tableName} table={table} />
                            </div>
                        </div>
                    } else {
                        return null
                    }
                })}
            </div>
            <div className='fixture-cards'>
                <div className='fixture-cards-flex'>
                    {fixtureCards.map(fixture => {
                        if (fixture.division === division || division === "all") {
                            return <Link
                                key={fixture.fixtureId}
                                className='card-links'
                                to={`/competitions/ards-and-district-spring-league-24/${fixture.fixtureId}`}
                            >
                                <FixtureCard
                                    title={fixture.date}
                                    home={fixture.home}
                                    away={fixture.away}
                                    division={fixture.division}
                                />
                            </Link>
                        } else {
                            return null;
                        }
                    })}
                </div>
            </div>
        </div>
    )
}