import React, { useState } from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

export default function SelectBox(props) {
    const [value, setValue] = useState('');

    const handleChange = (event) => {
        const selectedValue = event.target.value;
        setValue(selectedValue);
        props.onSelectedGroups(selectedValue);
    };

    return (
        <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
            <InputLabel id="demo-select-small-label">Division</InputLabel>
            <Select
                labelId="demo-select-small-label"
                id="demo-select-small"
                value={value}
                label="Division"
                onChange={handleChange}
            >
                <MenuItem value="all">All</MenuItem>
                <MenuItem value={"Division 1"}>Division 1</MenuItem>
                <MenuItem value={"Division 2"}>Division 2</MenuItem>
            </Select>
        </FormControl>
    );
}
