import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Link } from "react-router-dom";


export default function BasicTable({ id, headers, rows }) {
  return (
    <TableContainer component={Paper}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            {headers.map((header) => (
              <TableCell key={`${id}-${header}`} sx={{ fontWeight: 600, backgroundColor: '#f2f2f4' }}>{header}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => {
            let link = row.link ? row.link : "";
            return <TableRow
              key={`${id}-${row.name}-${row.date}`}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >
              {
                Object.entries(row).map(([key, value], i) => (
                  key !== 'link' ?
                    <TableCell key={`${id}-${row.name}-${key}`} scope="row">
                      <div>
                        {
                          link ?
                            <Link to={link}>
                              {value}
                            </Link>
                            : value
                        }
                      </div>
                    </TableCell>
                    : ''
                ))}
            </TableRow>
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}