import * as React from 'react';
import { Link, useLocation } from 'react-router-dom';
import Box from '@mui/material/Box';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import CottageRoundedIcon from '@mui/icons-material/CottageRounded';
import Paper from '@mui/material/Paper';
import FeedRoundedIcon from '@mui/icons-material/FeedRounded';
import TableViewRoundedIcon from '@mui/icons-material/TableViewRounded';
import HeadsetMicRoundedIcon from '@mui/icons-material/HeadsetMicRounded';

export default function BottomNav() {
    const pathname = useLocation();
    const [, setValue] = React.useState(pathname);

    const handleChange = newValue => {
        setValue(newValue);
    };

    return (
        <Box sx={{ width: 500 }} className='bottom-nav'>
            <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>
                <BottomNavigation
                    showLabels
                    value={useLocation().pathname}
                    onChange={handleChange}
                    sx={{ background: '#0c2e4e' }}
                >
                    <BottomNavigationAction component={Link} to="/" value='/'
                        label="Home" className='bottom-nav-icon' icon={<CottageRoundedIcon />} />
                    <BottomNavigationAction component={Link} to="/news" value='/news'
                        label="News" className='bottom-nav-icon' color='white' icon={<FeedRoundedIcon />} />
                    <BottomNavigationAction component={Link} to="/competitions"
                        value='/competitions' label="Competitions" className='bottom-nav-icon' color='white' icon={<TableViewRoundedIcon />} />
                    <BottomNavigationAction component={Link} to="/contact-us"
                        value='/contact-us' label="Contact" className='bottom-nav-icon' color='white' icon={<HeadsetMicRoundedIcon />} />
                </BottomNavigation>
            </Paper>
        </Box >
    );
}