import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Link } from 'react-router-dom';

function PlayerStatsTable({ playerStats }) {
    // Convert playerStats object into an array of [playerName, stats] pairs
    const playerStatsArray = Object.entries(playerStats);

    // Sort the array based on win ratio (descending) and number of matches played (descending)
    playerStatsArray.sort(([, statsA], [, statsB]) => {
        if (statsA.winRatio !== statsB.winRatio) {
            return statsB.winRatio - statsA.winRatio;
        }
        return statsB.played - statsA.played;
    });

    return (
        <TableContainer component={Paper} >
            <Table className='mobile-table' aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell sx={{ fontWeight: 600, backgroundColor: '#f2f2f4' }}>Player</TableCell>
                        <TableCell sx={{ fontWeight: 600, backgroundColor: '#f2f2f4' }}>P</TableCell>
                        <TableCell sx={{ fontWeight: 600, backgroundColor: '#f2f2f4' }}>W</TableCell>
                        <TableCell sx={{ fontWeight: 600, backgroundColor: '#f2f2f4' }}>L</TableCell>
                        <TableCell sx={{ fontWeight: 600, backgroundColor: '#f2f2f4' }}>Game (%)</TableCell>
                        <TableCell sx={{ fontWeight: 600, backgroundColor: '#f2f2f4' }}>Match (%)</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {playerStatsArray.map(([playerId, stats]) => (
                        <TableRow key={playerId}>
                            <TableCell>
                                <Link to={`/players/${playerId}`}>
                                    {stats.playerName}
                                </Link>
                            </TableCell>
                            <TableCell>
                                {stats.played}
                            </TableCell>
                            <TableCell>
                                {stats.wins}
                            </TableCell>
                            <TableCell>
                                {stats.losses}
                            </TableCell>
                            <TableCell>
                                {stats.gameRatio.toFixed()}%
                            </TableCell>
                            <TableCell>
                                {stats.winRatio.toFixed()}%
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

export default PlayerStatsTable;
