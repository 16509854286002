import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import teams from '../local-data-stores/teams.json'
import { Link } from 'react-router-dom';


function lookupTeamByName(row) {
  return teams.find(team => team.name === row.team)?.teamId;
}


export default function LeagueTable({ leagueName, table }) {

  const sortedTable = table.data.sort((a, b) => b.pts - a.pts)

  return (
    <TableContainer component={Paper} sx={{ marginBottom: '25px' }}>
      <Table className='mobile-table' sx={{ minWidth: '95%', backgroundColor: '#0a2540' }} aria-label="simple table">
        <TableHead sx={{ textTransform: "uppercase" }}>
          <TableRow>
            {Object.keys(table.data[0]).map((key, idx) => (
              <TableCell key={leagueName + key} sx={{ fontWeight: 600, color: 'white' }} align={idx === 0 ? 'left' : 'right'}>
                {key}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedTable.map((row) => {
            const team = row.team;
            const teamId = lookupTeamByName(row);
            return (
              <TableRow key={team} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                {Object.values(row).map((value, idx) => (
                  <TableCell
                    key={`${team}-${value}-${idx}`}
                    scope="row"
                    sx={{ color: 'white' }}
                    align={idx === 0 ? 'left' : 'right'}
                  >
                    {idx === 0 ? (
                      <Link className="league-table-links" to={`/teams/${teamId}`}>{value}</Link>
                    ) : value}
                  </TableCell>
                ))}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
