import * as React from 'react';
import BasicTable from '../../components/BasicTable';
import springLeague23 from '../../local-data-stores/spring-league-23-fixtures.json';
import springLeague24 from '../../local-data-stores/spring-league-24-fixtures.json';
import PlayerStatsTable from '../../components/PlayerStatsTable';
import { AccessTime } from '@mui/icons-material';


function createData(name, link, date) {
    return { name, link, date };
}

const ulsterRows = [
    createData('Ulster Rankings (Junior, Senior, & Masters)', 'https://www.tabletennisulster.com/rankings/', 'January, 2 2024 (PM)'),
];

const ardsClubRankings = [
    createData('1', '/players/fb4bf6a2-cd1c-4e49-bfb4-c9e5d3e7c05d', 'Albert Coulter'),
    createData('2', '/players/e497e686-6ef5-42f0-b52a-95bc7fbb35bc', 'Harold Milling'),
    createData('3', '/players/e7a13c90-d575-42fd-8d36-2b36a83da9f7', 'Ron Drain'),
    createData('4', '/players/6e43831a-4de2-43fb-83e3-39d6f04d4b3d', 'Chris Brennan'),
    createData('5', '/players/f3f94a1b-3724-4c34-8726-10a28272502e', 'Billy Mateer'),
    createData('6', '/players/5f8e7eb4-0ef0-42ee-aeb7-dbd0b181cf48', 'Ossie Patterson'),
    createData('7', '/players/64359557-cdf3-420f-8bc0-93fc38848eae', 'David Hamilton'),
    createData('8', '/players/14c0859f-ecbf-40cb-9cfb-19a5e0a2e78b', 'Jack Cash'),
    createData('9', '/players/c8b3f12b-9e11-4e78-99a3-d7322b108fc4', 'Michael Fry'),
    createData('10', '/players/c9eb470b-2515-4c4c-b6a4-38532e0e742f', 'John Brown'),
    createData('11', '/players/e09e2129-c6f9-4e77-8a33-7699f720a965', 'Josh Magowan'),
    createData('12', '/players/27a951ed-6655-4412-b191-af3afc414e2a', 'Dominic Ng'),
    createData('13', '/players/a07a1d50-2f3b-4ecb-bdc3-8df916b98143', 'Luke Rutherford'),
    createData('14', '/players/e235ff0f-67e4-49ff-8d56-fb1fb0e7a294', 'Ken Brittain'),
    createData('15', '/players/364a419f-084d-4916-b4e6-fb1237a6260e', 'Ed Ireland'),
    createData('16', '/players/76fdc9bc-da8c-4fcc-adfd-fe6dd3ca00e6', 'Nigel Morrison'),
    createData('17', '/players/addf2aac-0c3b-445d-a7ae-2f50eaf99a5b', 'Gavin Bateman'),
    createData('Not Ranked', '/players/dbb2d180-de0d-4b31-80a4-b48a6ddc8277', 'Paul Clarke'),
    createData('Not Ranked', '/players/2030a02c-7852-43fc-8a6b-12ad55d041c3', 'Tom Donnelly'),
    createData('Not Ranked', '/players/81ab6663-ee26-4429-b26b-cacfc996e8b8', 'Hugh Moore'),
    createData('Not Ranked', '/players/e47144e4-712a-467f-9d8f-697096b505eb', 'Filip Swerluga'),
    createData('Not Ranked', '/players/b3400b94-0072-4d1f-9185-504d0bcce27f', 'Cruze Moore'),
    createData('Not Ranked', '/players/fd1d6cdb-6e5f-41f0-af09-4b94be110cd6', 'Dante Ontorio'),
];

function calculatePlayerStats(data, competitionId, division) {
    const playerStats = {};

    // Filter matches by competitionId and division
    const matches = data.filter(obj => obj.division === division && obj.competition === competitionId);

    // Iterate over each match in the data
    matches.forEach(matchData => {
        matchData.matches.forEach(match => {
            const homePlayerId = match.home.playerId;
            const homePlayerName = match.home.playerName;
            const awayPlayerId = match.away.playerId;
            const awayPlayerName = match.away.playerName;

            if (homePlayerId && awayPlayerId) {
                // Update stats for home player
                if (homePlayerId in playerStats) {
                    playerStats[homePlayerId].played++;
                    playerStats[homePlayerId].wins += match.home.matchScore > match.away.matchScore ? 1 : 0;
                    playerStats[homePlayerId].losses += match.home.matchScore < match.away.matchScore ? 1 : 0;
                    playerStats[homePlayerId].gameWins += match.home.matchScore;
                    playerStats[homePlayerId].gameLosses += match.away.matchScore;
                } else {
                    playerStats[homePlayerId] = {
                        playerName: homePlayerName,
                        played: 1,
                        wins: match.home.matchScore > match.away.matchScore ? 1 : 0,
                        losses: match.home.matchScore < match.away.matchScore ? 1 : 0,
                        gameWins: match.home.matchScore,
                        gameLosses: match.away.matchScore
                    };
                }

                // Update stats for away player
                if (awayPlayerId in playerStats) {
                    playerStats[awayPlayerId].played++;
                    playerStats[awayPlayerId].wins += match.away.matchScore > match.home.matchScore ? 1 : 0;
                    playerStats[awayPlayerId].losses += match.away.matchScore < match.home.matchScore ? 1 : 0;
                    playerStats[awayPlayerId].gameWins += match.away.matchScore;
                    playerStats[awayPlayerId].gameLosses += match.home.matchScore;
                } else {
                    playerStats[awayPlayerId] = {
                        playerName: awayPlayerName,
                        played: 1,
                        wins: match.away.matchScore > match.home.matchScore ? 1 : 0,
                        losses: match.away.matchScore < match.home.matchScore ? 1 : 0,
                        gameWins: match.away.matchScore,
                        gameLosses: match.home.matchScore
                    };
                }
            }
        });
    });

    // Calculate win ratio for each player
    for (const playerId in playerStats) {
        const { played, wins, gameWins, gameLosses } = playerStats[playerId];
        const gamesPlayed = gameWins + gameLosses;
        playerStats[playerId].gameRatio = gamesPlayed > 0 ? (gameWins / gamesPlayed) * 100 : 0;
        playerStats[playerId].winRatio = played > 0 ? (wins / played) * 100 : 0;
    }

    return playerStats;
}


export default function Rankings() {

    const playerStatsSpring23DivOne = calculatePlayerStats(springLeague23, "Ards and District Spring League 2023", "Division 1");
    const playerStatsSpring23DivTwo = calculatePlayerStats(springLeague23, "Ards and District Spring League 2023", "Division 2");

    const playerStatsSpring24DivOne = calculatePlayerStats(springLeague24, "Ards and District Spring League 2024", "Division 1");
    const playerStatsSpring24DivTwo = calculatePlayerStats(springLeague24, "Ards and District Spring League 2024", "Division 2");


    return (
        <div className='article-container'>
            <section className='article-title'>
                <h1>Ulster Rankings</h1>
            </section>
            <section className='article-content'>
                <BasicTable id='ulster-rankings' headers={[]} rows={ulsterRows} />
            </section>
            <section className='article-title'>
                <h1>Spring League 2024: The Jack Cash Cup Rankings</h1>
            </section>
            <section className='article-content brand-title'>
                <h1>Division 1</h1>
                <PlayerStatsTable playerStats={playerStatsSpring24DivOne} />
            </section>
            <section className='article-content brand-title'>
                <h1>Division 2</h1>
                <PlayerStatsTable playerStats={playerStatsSpring24DivTwo} />
            </section>
            <section className='article-title'>
                <h1>Spring League 2023: The Jack Cash Cup Rankings</h1>
            </section>
            <section className='article-content brand-title'>
                <h1>Division 1</h1>
                <PlayerStatsTable playerStats={playerStatsSpring23DivOne} />
            </section>
            <section className='article-content brand-title'>
                <h1>Division 2</h1>
                <PlayerStatsTable playerStats={playerStatsSpring23DivTwo} />
            </section>
            <section className='article-title'>
                <h1>Ards Internal Club Rankings</h1>
                <p className='date'><span className='clock'><AccessTime fontSize="small" /></span>Monday, March 25, 2024</p>
                <p>1. You can challenge up to two places above your current ranking</p>
                <p>2. You can play two ranked matches per night</p>
                <p>3. Best of five games</p>
                <p>4. The winner must update the ranking board</p>
                <p>5. Try and get an umpire for your games</p>
            </section>
            <section className='article-content'>
                <BasicTable id='ards-club-rankings' headers={['Position', 'Player']} rows={ardsClubRankings} />
            </section>
        </div>
    )
}
