import * as React from 'react';
import { useParams } from 'react-router-dom';
import { AccessTime } from '@mui/icons-material';
import newsArticles from '../news.json';
import Carousel from '../../../components/Carousel';
import { Link } from 'react-router-dom';


export default function NewsArticle() {
    const { id } = useParams();
    const article = newsArticles.find(article => String(article.id) === id);

    const itemData = article?.images

    // Function to convert HTML string to JSX
    const createMarkup = (htmlString) => {
        return { __html: htmlString };
    };

    return (
        <div className='article-container'>
            <section className='article-title'>
                <h1>{article.title}</h1>
                <h2>{article.summary}</h2>
                <h3 className='author'>{article.author.name}</h3>
                <p className='author-role'>{article.author.role}</p>
                <p className='date'><span className='clock'><AccessTime fontSize="small" /></span>{article.date}</p>
            </section>

            {
                itemData ?
                    <Carousel sliderItems={itemData} interval={3000} /> :
                    <div className='article-image'>
                        <img src={article.path} alt={article.alt} className='d-block w-100' />
                    </div>
            }
            <section className='article-content'>
                {/* Render article content with HTML conversion */}
                {article.content.map((paragraph, idx) => {
                    if (paragraph.includes("<h3>")) {
                        return <h3 key={article.id + idx}>{paragraph.replace("<h3>", "").replace("</h3>", "")}</h3>;
                    } else if (paragraph.includes("<Link")) {
                        // Handle React Router Link within HTML content
                        const linkComponent = React.createElement(
                            Link,
                            { to: '/rankings', key: article.id + idx, className: "news-article-link" },
                            paragraph.replace(/<\/*Link.*?>/g, '') // Remove <Link> tags
                        );
                        return linkComponent;
                    } else {
                        // Render regular paragraph
                        return <p key={article.id + idx} dangerouslySetInnerHTML={createMarkup(paragraph)} />;
                    }
                })}
            </section>
        </div>
    )
}