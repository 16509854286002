import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import DoneIcon from '@mui/icons-material/Done';
import Paper from '@mui/material/Paper';
import PersonIcon from '@mui/icons-material/Person';
import { Link } from 'react-router-dom';


export default function MatchTable({ fixture, match, homeLogoPath, awayLogoPath }) {
  const homePlayer = match.home.playerName;
  const awayPlayer = match.away.playerName;
  const homePlayerId = match.home.playerId;
  const awayPlayerId = match.away.playerId;


  return (
    <TableContainer component={Paper}>
      <Table aria-label="simple table" className='match-table'>
        <TableHead sx={{ textTransform: "uppercase" }}>
        </TableHead>
        <TableBody>
          <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <TableCell
              scope="row"
            >
              {match.home.matchScore > match.away.matchScore ? <DoneIcon /> : ""}
            </TableCell>
            <TableCell
              scope="row"
            >
              {
                homeLogoPath ?
                  <Link to={`/teams/${fixture.home.teamId}`}>
                    <img src={homeLogoPath} height='60' width='60' alt='home-logo' />
                  </Link>
                  :
                  <Link to={`/players/${fixture.home.playerId}`}>
                    <PersonIcon fontSize='large' />
                  </Link>
              }
            </TableCell>
            <TableCell
              scope="row"
            >
              <Link
                to={`/players/${homePlayerId}`}
              >
                {homePlayer}
              </Link>
            </TableCell>
            <TableCell
              scope="row"
              sx={{ background: '#f2f2f4' }}
            >
              {match.home.matchScore}
            </TableCell>
            {match.home.gameScores.map((score, idx) => {
              return <TableCell
                key={fixture.id + 'away' + idx}
                scope="row"
              >
                {score}
              </TableCell>
            })}
          </TableRow>
          <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <TableCell
              scope="row"
            >
              {match.away.matchScore > match.home.matchScore ? <DoneIcon /> : ""}
            </TableCell>
            <TableCell
              scope="row"
            >
              {awayLogoPath ?
                <Link to={`/teams/${fixture.away.teamId}`}>
                  <img src={awayLogoPath} height='60' width='60' alt='away-logo' />
                </Link>
                :
                <Link to={`/players/${fixture.away.teamId}`}>
                  <PersonIcon fontSize='large' />
                </Link>
              }
            </TableCell>
            <TableCell
              scope="row"
            ><Link
              to={`/players/${awayPlayerId}`}
            >
                {awayPlayer}
              </Link>
            </TableCell>
            <TableCell
              scope="row"
              sx={{ background: '#f2f2f4' }}
            >
              {match.away.matchScore}
            </TableCell>
            {match.away.gameScores.map((score, idx) => {
              return <TableCell
                key={fixture.id + 'away' + idx}
                scope="row"
              >
                {score}
              </TableCell>
            })}
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}
