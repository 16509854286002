import * as React from 'react';
import BasicTable from '../../components/BasicTable';

function createData(name, date, cost) {
    return { name, date, cost };
}

const rows = [
    createData('Adult', 'Crowdfunder Donation, Annual, Monthly, Pay as you Go', '£100 per annum, or 10 per month (standing order), or £5 pay-as-you-go per session'),
    createData('Concession (Junior/Student/Unemployed etc.)', 'Crowdfunder Donation, Annual, Monthly, Pay as you Go', '£50 per annum or 5 per month (standing order), or £3 pay-as-you-go per session'),
];


export default function Membership() {
    return (
        <div className='article-container'>
            <section className='article-title'>
                <h1>Membership</h1>
                <h2>Membership Prices Effective September 1, 2024</h2>
            </section>
            <section className='article-content'>
                <BasicTable id='membership' headers={['Membership Type', 'Payment Method', 'Cost']} rows={rows} />
            </section >
            <p className='p-italic'>
                **At Ards TT Club, we operate on an honour system. The membership dues outlined above are recommended contributions that help cover the club's operational costs and ensure we can continue providing a space for table tennis. Our goal is not to make a profit from our members but to keep the club running for everyone’s enjoyment.

                While these contributions are greatly appreciated, they are not mandatory. If you are unable to pay, you are still very welcome to play and participate in all club activities.
            </p>
        </div>
    )
}