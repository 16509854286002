import * as React from 'react';


export default function Equity() {
    return (
        <div className='article-container'>
            <section className='article-title'>
                <h1>Equity and Child Protection Policy</h1>
            </section>
            <section className='article-content'>
                <p>Ards Table Tennis Club's Policies on Equity and Child Protection.</p>

                <h2>Goals</h2>

                <p>The primary aim of Ards Table Tennis Club is to foster and promote the growth of Table Tennis in the Ards area and its surrounding community while adhering to the club's equity policy.</p>
                <p>The club is particularly dedicated to encouraging and attracting young players to the sport and nurturing their skills through well-planned and organized coaching sessions.</p>
                <p>These objectives can only be accomplished in a friendly yet structured environment where all players are equally committed to playing Table Tennis.</p>

                <h2>Equity Policy</h2>

                <p>
                    Ards Table Tennis Club is fully committed to incorporating equity in all aspects of its development. In doing so, it acknowledges and embraces the following definition of sports equity from Table Tennis Ulster:
                </p>

                <blockquote>
                    <p>
                        Sports equity entails fairness in sport, equal access for all, recognizing inequalities, and taking measures to address them. It involves transforming the culture and structure of the sport to ensure equal accessibility for everyone in society.
                    </p>
                </blockquote>

                <p>
                    Ards Table Tennis Club respects the rights, dignity, and value of every individual, treating all participants equally within the context of their sport, regardless of age, ability, gender, race, ethnicity, religious beliefs, sexuality, or social/economic status.
                </p>

                <p>
                    The club is determined to provide an environment where all members can enjoy their sport without facing threats, intimidation, harassment, or abuse.
                </p>

                <p>
                    Every club member has the responsibility to oppose discriminatory behavior and promote equal opportunities. Any instances of the mentioned behavior reported to the committee will be promptly addressed and appropriate action will be taken.
                </p>

                <p>Signed by:</p>

                <p>
                    Chairperson: Chris Brennan<br />
                    Secretary: Billy Mateer<br />
                    Treasurer: Chris Brennan
                </p>

            </section>
        </div>
    )
}