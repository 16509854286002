import * as React from 'react';
import { useParams } from 'react-router-dom';
import LeagueTable from '../../../components/LeagueTable.js';
import results from '../../../local-data-stores/des-flanagan-23-results.json';
import MatchTable from '../../../components/MatchTable.js';
import Paper from '@mui/material/Paper';


export default function EventResults() {
    const { eventId } = useParams();
    const event = results.events.find(event => event.id === eventId)

    return (
        <div className='page-container' >
            <div className='brand-title' style={{ paddingLeft: '10px' }}>
                <h1>Des Flanagan Investment Solutions Ulster Series Round 1 – Ards</h1>
                <h2>{event.name} Results</h2>
            </div>
            <section className='league-tables'>
                <div className='Left-body'>
                    <div className='brand-title' style={{ paddingLeft: '10px' }}>
                        <div className='flex-space'>
                            <h1>Knockout Stage</h1>
                        </div>
                    </div>
                    <div>
                        {event.stages.map(stage => {
                            return (stage.section === "knockout-stage") ? 
                            <Paper key={`${stage.section}-${event.name}-${stage.tableName}`} elevation={24} sx={{ padding: '15px', marginBottom: 5 }}>
                                <div className='brand-title'>
                                    <div className='league-division-container'>
                                        <h2>{stage.tableName}</h2>
                                        {
                                            stage.matches.map((match, idx) => {
                                                return <div key={stage.tableName + match.home.playerName + match.away.playerName}>
                                                    <h2>Match {idx + 1}</h2>
                                                    <MatchTable fixture={match} match={match} />
                                                </div>
                                            })
                                        }
                                    </div>
                                </div>
                            </Paper> : null
                        })}
                    </div>

                    <div className='brand-title' style={{ paddingLeft: '10px' }}>
                        <div className='flex-space'>
                            <h1>Group Stage</h1>
                        </div>
                    </div>
                    <div>
                        {event.stages.map(stage => {
                            return (stage.section === "group-stage") ? 
                            <Paper key={`${stage.section}-${event.name}-${stage.tableName}`} elevation={24} sx={{ padding: '15px', marginBottom: 5 }}>
                                <div className='brand-title'>
                                    <div className='league-division-container'>
                                        <h2>{stage.tableName}</h2>
                                        <LeagueTable leagueName={event.name} table={stage} />
                                        {
                                            stage.matches.map((match, idx) => {
                                                return <div key={stage.section + stage.tableName + match.home.playerName + match.away.playerName}>
                                                    <h2>Match {idx + 1}</h2>
                                                    <MatchTable fixture={match} match={match} />
                                                </div>
                                            })
                                        }
                                    </div>
                                </div>
                            </Paper> : null
                        })}
                    </div>

                    <div className='brand-title' style={{ paddingLeft: '10px' }}>
                        <div className='flex-space'>
                            <h1>Consolation Knockout</h1>
                        </div>
                    </div>

                    <div>
                        {event.stages.map(stage => {
                            return (stage.section === "consolation-knockout") ? 
                            <Paper key={`${stage.section}-${event.name}-${stage.tableName}`} elevation={24} sx={{ padding: '15px', marginBottom: 5 }}>
                                <div className='brand-title'>
                                    <div className='league-division-container'>
                                        <h2>{stage.tableName}</h2>
                                        {
                                            stage.matches.map((match, idx) => {
                                                return <div key={stage.tableName + match.home.playerName + match.away.playerName}>
                                                    <h2>Match {idx + 1}</h2>
                                                    <MatchTable fixture={match} match={match} />
                                                </div>
                                            })
                                        }
                                    </div>
                                </div>
                            </Paper> : null
                        })}
                    </div>
                </div>
            </section >
        </div >
    )
}