import * as React from 'react';
import ActionAreaCard from './ActionAreaCard';
import { Link } from "react-router-dom";


export default function News({newsArticles}) {
    return (
        <div className='news-body'>
            <div className='news-cards'>
                {newsArticles.map(article =>
                    <Link key={article.id} className='card-links' to={`/news/${article.id}`}>
                        <ActionAreaCard
                            path={article.path}
                            alt={article.alt}
                            title={article.title}
                            body={article.summary}
                            date={article.date} />
                    </Link>
                )}
            </div>
        </div>
    )
}