import * as React from 'react';
import { useParams, useLocation } from 'react-router-dom';
import MatchTable from '../../components/MatchTable';
import fixtures23 from '../../local-data-stores/spring-league-23-fixtures.json';
import fixtures24 from '../../local-data-stores/spring-league-24-fixtures.json';
import { Link } from 'react-router-dom';

import { Paper } from '@mui/material';

export default function MatchReport() {
    const { id } = useParams();
    const location = useLocation()

    const competiton = location.pathname.split('/')[2];
    const fixtures = (competiton === 'ards-and-district-spring-league-24') ? fixtures24 : fixtures23
    const fixture = fixtures.find(fixture => String(fixture.fixtureId) === id);

    return (
        <div>
            <div className='diagonal-outer-grey'>
                <div className='diagonal-inner transition center'>
                    <h1>{fixture.competition}</h1>
                    <p style={{ wordWrap: 'nowrap' }}>
                        <Link style={{textDecoration: 'none'}} to={`/teams/${fixture.away.teamId}`}>
                            {fixture.home.name}
                        </Link>
                        <span> Vs </span>
                        <Link style={{textDecoration: 'none'}} to={`/teams/${fixture.away.teamId}`}>
                            {fixture.away.name}
                        </Link> </p>
                    <p className='match-score'>{fixture.home.score} - {fixture.away.score}</p>
                </div>
            </div>
            <section className='quaternary-background'>
                <div className='match-report-container' >
                    <Paper elevation={24}>
                        <div className='match-report'>
                            <div className='match-logos'>
                                <div>
                                    <Link to={`/teams/${fixture.home.teamId}`}>
                                        <img src={fixture.home.path} alt='home-logo' />
                                    </Link>
                                </div>
                                <div>
                                    <Link to={`/teams/${fixture.away.teamId}`}>
                                        <img src={fixture.away.path} alt='away-logo' />
                                    </Link>
                                </div>
                            </div>
                            <h1 className='center'>Match Report</h1>
                            {fixture.matches.map((match, idx) =>
                                <div key={fixture.fixtureId + idx}>
                                    <h2>Match {idx + 1}</h2>
                                    <MatchTable fixture={fixture} match={match} homeLogoPath={fixture.home.path} awayLogoPath={fixture.away.path} />
                                </div>
                            )}
                        </div>
                    </Paper>
                </div>
            </section>
        </div>
    )
}