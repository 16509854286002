import * as React from 'react';


export default function Contact() {
    return (

        <div className='article-container'>
            <section className='article-title'>
                <h1>Contact Us</h1>
            </section>
            <section className='article-content'>
                <h2>Billy Mateer</h2>
                <h2>Tel: 07484252671</h2>
                <h2>Email: <a href="mailto:contact@ardstabletennisclub.com">contact@ardstabletennisclub.com</a></h2>
            </section>
        </div>
    )
}