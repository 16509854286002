import * as React from 'react';
import { Link } from 'react-router-dom';
import NewsPage from '../news/NewsPage';
import { Button } from '@mui/material';

export default function Home() {
    return (
        <div>
            <div className='diagonal-outer-white'>
                <div className='diagonal-inner transition'>
                    <h1>Welcome to Ards Table Tennis Club</h1>
                    <p>Whether you are a beginner or a seasoned pro, our club offers a friendly and inclusive environment for players of all ages. We strive to foster a sense of community and teamwork while improving the sport of Table Tennis.</p>
                    <p>Join us today</p>
                    <Button component={Link} to='/contact-us' className='button-primary'>Join</Button>
                </div>
            </div>
            <div className='home-contact-div'>
                <img className="d-block w-100 home-contact-img" src='/images/slider/contact-slider.png' alt='Contact Billy Mateer' />
            </div>
            <div className='featured'>
                <Link to="./news/hugh-moore-appointed-as-ards-tt-club-president">
                    <div className='featured-left'>
                        <div className='text-overlay-container'>
                            <h1 className='text-overlay'>Hugh Moore Appointed as Ards TT Club President</h1>
                        </div>
                    </div>
                </Link>
                <div className='featured-right'>
                    <Link to="./news/ards-tt-shines-at-jimmy-ohara-cup">
                        <div className='featured-right-top'>
                            <div className='text-overlay-container'>
                                <h2 className='text-overlay'>Ards TT Club Shines at the Jimmy O'Hara Memorial Cup</h2>
                            </div>
                        </div>
                    </Link>
                    <Link to="./news/cash4clubs-grant-24">
                        <div className='featured-right-bottom'>
                            <div className='text-overlay-container'>
                                <h2 className='text-overlay'>Ards TT Club Celebrates Cash4Clubs Grant in Partnership with Sported</h2>
                            </div>
                        </div>
                    </Link>
                </div>
            </div>
            <div className='page-container tertiary-background'>
                <div className='App-body'>
                    <div className='Left-body brand-title-light-blue brand-content-2'>
                        <h1>What is ATTC?</h1>
                        <p>Ards Table Tennis Club (ATTC) is situated in the Centre of Newtownards and welcomes players of all ages and levels. We practice on Wednesday evenings at the Queens Hall, West St, Newtownards. Coaching sessions are ran on an ad hoc basis by certified Level 1, 2, and 3 coaches</p>
                        <p>Contact <b>Billy Mateer</b> for more information on:</p>
                        <p><b>Tel:</b> 07484252671</p>
                        <p><b>Email: </b><a href="mailto:contact@ardstabletennisclub.com">contact@ardstabletennisclub.com</a></p>
                    </div>
                    <div className='Right-body brand-title-light-blue brand-content-2'>
                        <h1>Beginner</h1>
                        <p>Just starting... No problem! We offer beginner sessions for both adults and juniors</p>
                        <h1>Intermediate or Advanced</h1>
                        <p>Played before or getting ready for your first competitive league match? Why not try our intermediate or advanced coaching sessions</p>
                    </div>
                </div>
                <div className='brand-title-light-blue'>
                    <h1 className='center'>Quick Links</h1>
                </div>
                <div className='home-quick-links'>
                    <Link to="./competitions/ards-and-district-spring-league-24">
                        <Button variant="contained">Spring League</Button>
                    </Link>
                    <Link to="./rankings">
                        <Button variant="contained">Rankings</Button>
                    </Link>
                    <Link to="./contact-us">
                        <Button variant="contained">Contact Us</Button>
                    </Link>
                    <Link to="./news/">
                        <Button variant="contained">News</Button>
                    </Link>
                </div>
            </div>
            <section className='news-section'>
                <div className='page-container'>
                    <NewsPage />
                </div>
            </section>
        </div>
    )
}