import * as React from 'react';


export default function Report() {
    return (
        <div className='page-container'>
            <section className='brand-title-blue'>
                <h1>Report a website issue</h1>
            </section>
        </div>
    )
}