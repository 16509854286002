import * as React from 'react';
import { Link } from 'react-router-dom';
import '../App.css'

export default function Footer() {
    return (
        <section className='footer-diagonal-container'>
            <footer className='footer'>
                <div className="footer-logo-text">
                    <img src='/images/ards-logo-white.png' height='81' width='150' alt='ards-tt-logo-white' />
                </div>
                <h3>Our partners</h3>
                <div className='footer-partners'>
                    <Link to="https://www.finlayelectrical.co.uk/">
                        <img className='rounded-partner-logo' src='/images/partners/finlay-logo.jpg' height='50' width='100' alt='ards-tt-logo-white' />
                    </Link>
                    <Link to="https://thehub.sported.org.uk/">
                        <img src='/images/partners/sported-logo-1.png' height='81' width='150' alt='ards-tt-logo-white' />
                    </Link>
                    <Link to="https://www.tabletennisulster.com/">
                        <img src='/images/partners/table-tennis-ulster-logo.png' height='60' width='60' alt='ards-tt-logo-white' />
                    </Link>
                </div>
                <div className='social-media'>
                    <img className='social-media-icon' src='/images/social-media/linkedin.png' width={40} height={40} alt='LinkedIn' />
                    <Link to="https://www.facebook.com/groups/710105057872347/">
                        <img className='social-media-icon' src='/images/social-media/facebook.png' width={40} height={40} alt='Facebook' />
                    </Link>
                    <img className='social-media-icon' src='/images/social-media/twitter.png' width={40} height={40} alt='Twitter' />
                </div>
                <div className='footer-links'>
                    <ul>
                        <Link to="./contact-us">Contact us</Link>
                        <Link to="./privacy-policy">Privacy Policy</Link>
                        <Link to="./equity-policy">Equity Policy</Link>
                        <Link to="./cookie-policy">Cookie policy</Link>
                        <Link to="./club-constitution">Constitution</Link>
                        <Link to="./terms-of-use">Terms of use</Link>
                        <Link to="./report-an-issue">Report a website issue</Link>
                    </ul>
                </div>
                <p>© 2023 Ards Table Tennis Club. All Rights Reserved</p>
            </footer>
        </section >
    )
}