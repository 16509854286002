import * as React from 'react';
import { AccessTime } from '@mui/icons-material';
import BasicTable from '../../components/BasicTable';

export default function Events() {

    function createData(name, link, date) {
        return { name, link, date };
    }
    
    const rows = [
        createData('Ards Masters Over 60s', '/competitions/des-flanagan-series-sep-23/ards-masters-over-60s', 'September, 2 2023 (PM)'),
        createData('Ards Open', '/competitions/des-flanagan-series-sep-23/ards-open', 'September, 2 2023 (PM)'),
        createData('Ards Masters over 40s', '/competitions/des-flanagan-series-sep-23/ards-masters-over-40s', 'September, 2 2023 (AM)'),
    ];

    return (
        <div className='article-container'>
            <section className='article-title'>
                <h1>Des Flanagan Investment Solutions Ulster Series Round 1 – Ards</h1>
                <h2>In collaboration with Table Tennis Ulster, Ards Table Tennis Club is set to commence the Des Flanagan Investment Solutions Series for the 2023/24 season. The inaugural event of this ten-part series will take place at Queens Hall, located on West Street in Newtownards.</h2>
                <h3 className='author'>Adrian Brown</h3>
                <p className='author-role'>Table Tennis Ulster</p>
                <p className='date'><span className='clock'><AccessTime fontSize="small" /></span>Saturday 2nd September</p>
            </section>
            <div className='article-image'>
                <img src='/images/news/ards-ready-for-des-flanagan-series.jpg' alt='Ards Table Tennis Club getting ready to host the first event in the Des Flanagan Investment Series' className='d-block w-100' />
            </div>
            <section className='article-content'>
                <h1>Round One - Saturday 2nd September 2023 - Hosted by Ards TT Club</h1>

                <p><strong>Venue</strong> - Queens Hall - West Street, Newtownards BT23 4EN</p>

                <p><strong>Schedule - approx. times</strong></p>
                <ul>
                    <li>OVER 40's SINGLES - 9.00 am</li>
                    <li>MIXED OPEN - 1.30 pm</li>
                    <li>OVER 60's SINGLES - 1.30 pm</li>
                </ul>

                <p>Players may not enter two events starting at the same time.</p>
                <p>Check-in will be 30 minutes before the scheduled start of your first event.</p>

                <p><strong>Entry fee</strong> - £12 per category or £20 if entering two categories.</p>
                <p><strong>Entries to</strong> Adrian Brown at <a href="mailto:aidyvbrown@gmail.com">aidyvbrown@gmail.com</a> or Tel. 07713 678644</p>
                <p><strong>Closing Date for entries</strong> - Friday 25th August 2023</p>

                <p><strong>Tournament Referee</strong>: Adrian Brown, <strong>Assistant Referee</strong>: Adam Brown</p>

                <h3>Tournament Regulations</h3>

                <p>A condition of entry to each event is that players agree to abide by TTU's Code of Conduct which can be accessed on TTU's website <a href="https://www.tabletennisulster.com/code-of-conduct/">https://www.tabletennisulster.com/code-of-conduct/</a></p>

                <p>All categories will be played on a Round Robin format for the first round. The top 2 in each group will progress to the second round. A consolation event will be held for players not qualifying for the second round. Matches will be the best of 5 games unless otherwise specified by the referee.</p>
                <p>The final order in a group shall be determined as per ITTF regulation 3.7.5 for group competitions except that where a player concedes a match he/she shall receive -3 group points.</p>
                <p>All players will be expected to umpire when required.</p>
                <p>All players must report to the control table on arrival and must not leave the venue while still in the competition without first obtaining permission from the Referee.</p>
                <p>The Tournament Referee reserves the right to alter the format of the draw, cancel any category and to restrict admission to the tournament venue. If a category is over-subscribed, then entries will be treated on a last in-first out basis.</p>
                <p><strong>Event Entry Restrictions</strong>: All players must be affiliated to the ITTA. Masters age restrictions - 40/60 years old by the end of the calendar year in which the tournament takes place.</p>
                <p>All disputes will be dealt with by the tournament referee and/or TTU's Events Committee whose decision will be final.</p>
                <p><strong>Prizemoney</strong> will be awarded to the winner and runner-up in each category. Trophies will be awarded to the winner and runner-up in each consolation event.</p>
                <p>Ulster ranking points will be awarded in the Mixed Open and Over 40's categories.</p>
                <p>Play throughout will be on Stiga and Donic tables. All games will be played using Butterfly R40+ 3 star balls.</p>
            </section>
            <section className='article-title'>
                <h1>Results</h1>
                <BasicTable id='events-page' headers={['Event Name', 'Date']} rows={rows} />
            </section>

        </div>






    )
}