import React, { useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import CancelIcon from '@mui/icons-material/Cancel';
import { Link, Outlet } from "react-router-dom";

export default function NavBar() {
    const [open, setState] = useState(false);

    const toggleDrawer = (open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setState(open);
    };


    return (
        <Box sx={{ flexGrow: 1 }}>
            <AppBar position="static" sx={{ height: 150, background: "#0a2540" }} >
                <Toolbar sx={{ minHeight: 150, flex: 1 }}>
                    <div className='menu-bar'>

                        <Link className='logo-text' to="./">
                            <img src='/images/ards-logo-white.png' height='81' width='150' alt='ards-tt-logo-white' />
                        </Link>

                        <div className='nav-items hidden-mobile'>
                            <Link to="/"><Button color="inherit">Home</Button></Link>
                            <Button color="inherit"><Link to="/news">News</Link></Button>
                            <Link to="/about-us"><Button color="inherit">About Us</Button></Link>
                            <Link to="/membership"><Button color="inherit">Membership</Button></Link>
                            <Link to="/competitions"><Button color="inherit">Competitions</Button></Link>
                            <Link to="/rankings"><Button color="inherit">Rankings</Button></Link>
                            <Link to="/events"><Button color="inherit">Events</Button></Link>
                            <Button color="inherit"><Link to="/contact-us">Contact Us</Link></Button>
                        </div>

                        {/* hamburger icon shows the drawer on click */}
                        <IconButton
                            edge="start"
                            color="inherit"
                            aria-label="open drawer"
                            onClick={toggleDrawer(true)}
                            sx={{ mr: 2, display: { sm: 'block', md: 'none', }, }}>
                            <MenuIcon />
                        </IconButton>

                        <Drawer
                            anchor="right" //from which side the drawer slides in
                            variant="temporary" //if and how easily the drawer can be closed
                            open={open} //if open is true, drawer is shown
                            onClose={toggleDrawer(false)} //function that is called when the drawer should close
                        >

                            <Box className="hamburger-menu">
                                <div className='hamburger-menu-internal-icon'>

                                    <Link className='logo-text' to="./">
                                        <img src='/images/ards-logo-white.png' height='81' width='150' alt='ards-tt-logo-white' />
                                    </Link>

                                    <IconButton
                                        edge="start"
                                        color="inherit"
                                        aria-label="open drawer"
                                        onClick={toggleDrawer(false)}
                                        sx={{ mr: 2, display: { sm: 'block', md: 'none', }, color: 'white' }}>
                                        <CancelIcon />
                                    </IconButton>
                                </div>
                                <div className='hamburger-menu-items nav-items' onClick={toggleDrawer(false)}>
                                    <Link to="/">Home</Link>
                                    <Link to="/news">News</Link>
                                    <Link to="/about-us">About Us</Link>
                                    <Link to="/membership">Membership</Link>
                                    <Link to="/competitions">Competitions</Link>
                                    <Link to="/events">Events</Link>
                                    <Link to="/rankings">Rankings</Link>
                                    <Link to="/contact-us">Contact Us</Link>
                                </div>
                            </Box>
                        </Drawer>
                    </div>
                </Toolbar>
            </AppBar>
            <Outlet />
        </Box >
    );
}

